import React from "react";
import { BrowserRouter, Routes, Route,Navigate  } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
// import {KategoriaVypis1} from "containers/KategoriaVypis1";
// import PageCollection3 from "../containers/PageCollection3";
import KategoriaVypis1 from "../containers/KategoriaVypis1";
import ProductsSearchList from "../containers/ProductsSearchList";
import Vop from "../containers/BlogPage/VOP";
import Kontakt from "../containers/BlogPage/kontakt";
import VratenieTovaru from "../containers/BlogPage/vratenie-tovaru";


export const pages: Page[] = [
  /* { path: "/", component: PageHome },*/
  { path: "/", component: ProductsSearchList },
  { path: "/home2", component: PageHome2 },
  { path: "/home3", component: PageHome3 },
  //
  { path: "/home-header-2", component: PageHome },
  { path: "/produkt/:slug", component: ProductDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  { path: "/page-collection-2", component: PageCollection2 },
   { path: "/kategoria", component: KategoriaVypis1 },

  {
    path: "/brand/ems",
    component: () => <Navigate to="/produkty/all?filters=%7B%22100%22%3A%5B%22235%22%5D%7D&productsCount=40" replace />,
  },

  { path: "/category/:slug", component: KategoriaVypis1 },
  // { path: "/kategoria", component: KategoriaVypis1 },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  { path: "/produkty/:categorySlug", component: ProductsSearchList },

  { path: "/produkty", component: ProductsSearchList },

  //
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  //
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/vseobecne-obchodne-podmienky", component: Vop },
  { path: "/vratenie-tovaru", component: VratenieTovaru },
  { path: "/kontakt", component: Kontakt },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;

import create from "zustand";

export const useMetaStore = create((set) => ({
    title: "Default Title",
    description: "Default description.",
    image: "https://example.com/default-image.jpg",
    url: "https://example.com",
    setMeta: (meta) =>
        set((state) => ({
            ...state,
            ...meta,
        })),
}));

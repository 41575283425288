import React, {FC, useEffect, useRef, useState} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";

// @ts-ignore
import InnerImageZoom from 'react-inner-image-zoom';
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import Policy from "./Policy";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import useCartStore from "../../store/cartStore";
import useEshop from "../../store/eshopStore";
import Label from "../../components/Label/Label";
import Select from "../../shared/Select/Select";
import {Dialog} from "@headlessui/react";
import ButtonClose from "../../shared/ButtonClose/ButtonClose";
import NcImage from "../../shared/NcImage/NcImage";


export interface ProductDetailPageProps {
  className?: string;
}



const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const eshop = useEshop((state) => state.settings);
  const { variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
    const navigate = useNavigate();

  const [sizes, setSizes] = React.useState([]);
  const productIsLoading = useRef(false);
  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState("");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const { slug } = useParams();
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);
    const cartStore=useCartStore()
    // const [productIsLoading, setProductIsLoading] = useState(false);
    const [product, setProduct] = useState(null);
    const updateCount = (newCount: any) => {
        setProduct((prevProduct) => ({
            // @ts-ignore
            ...prevProduct,
            count: newCount,
        }));
    };

    const [isOpenSizeChart, setIsOpenSizeChart] = useState(false);
    const completeButtonRef = useRef<HTMLDivElement>(null);
    const onCloseSizeChart = () => {
        setIsOpenSizeChart(false);
    };

    // Function to update product size
    const updateSize = (newSize: any) => {
        setProduct((prevProduct) => ({
            // @ts-ignore
            ...prevProduct,
            size: newSize,
        }));
    };
    const loadProduct = () => {
        productIsLoading.current=true
        let headers={}

        const params = {
            'action': 'get_product',
            'slug': slug,
        }
        axios.get('https://api.mojavec.com/v1/get/', {
            headers,
            params
        })
            .then((response => {
                // console.log('get_products response: ',response)
                if (response.data.data) {
                    const data = response.data.data;
                    const meta = response.data.meta;
                    if (data) {
                        let productType = '';
                        if (data.tags.includes("98")) productType = 'tShirt'; //tricko
                        if (data.tags.includes("127")) productType = 'sweatShirt'; //mikina
                        if (data.tags.includes("217")) productType = 'cap'; //siltovka
                        let productImgPath='';
                        if (data.is_hashed_path) {
                             productImgPath = eshop.mainUrl + 'web_files/' + data.image;
                        } else {
                            const imgDate = new Date(data.created_at_hard);
                            let imgYear = imgDate.getFullYear();
                            let imgMonth = String(imgDate.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
                             productImgPath = eshop.mainUrl + 'web_files/' + imgYear + '/' + imgMonth + '/' + data.image;
                        }
                        setCurrentImage(productImgPath)

                        const getInitialCutValue = (

                            data: { tags: string | string[]; }) => {
                            if (data.tags.includes("153")) return 'damsky_strih';
                            if (data.tags.includes("152")) return 'pansky_strih';
                            if (data.tags.includes("174")) return 'unisex_strih';
                            if (data.tags.includes("172")||data.tags.includes("173")||data.tags.includes("154")) return 'detsky_strih';
                            return ''; // or some default value
                        };

                        setProduct({
                            ...data,
                            count: 1,
                            color: '',
                            size: (productType==='tShirt'||productType==='sweatShirt')?sizeSelected:'',
                            cutFemale: (data.tags.includes("153")) ? true : false, //pre zeny
                            cutMale: (data.tags.includes("152")) ? true : false, //pre muzov,
                            cutUnisex: (data.tags.includes("174")) ? true : false, //pre unisex
                            forKids: (data.tags.includes("172")||data.tags.includes("173")||data.tags.includes("154")) ? true : false, //pre deeti
                            selectedCut: getInitialCutValue(data),
                            type: productType,
                            productImg: productImgPath
                        });
                        // tag panske== 152
                        // tag zenske = 153
                        // unisex = 174

                        //generate sizes

                        // @ts-ignore
                        let productSizes=[];
                        console.log()
                        for (let tag of data.tags) {
                            // console.log(tag)
                            // eshop.allSizes[tag]
                            // @ts-ignore

                            // else
                            if (eshop.allSizes[tag]) {

                                if((tag==="152"||tag==="153"||tag==="174"||tag==="172"||tag==="173")){ // tieto tagy sa pridaju len pri siltovke
                                    if(productType==='cap')
                                    productSizes.push(eshop.allSizes[tag]);
                                }else
                                productSizes.push(eshop.allSizes[tag]);
                            }
                        }
                        // @ts-ignore
                        if (productSizes.length > 0)setSizes(productSizes)
                        setSizeSelected(productSizes[0])
                        // console.log('productSizes')
// @ts-ignore
                        // console.log(productSizes)
                        // console.log('eshop.femaleSizes')

                        // console.log(eshop.femaleSizes)

/*
                        if (data.tags.includes("153")) {
                            setSizes(eshop.femaleSizes)
                        } else setSizes(eshop.maleSizes)

 */
                        // setProduct(data)
                    }
                }else
                if (response.data.error) {
                    const error = response.data.error;
                    // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
                }else{setProduct(null)}

                productIsLoading.current=false

            }))
            .catch(error => {
                productIsLoading.current=false
                // console.log("any force_select_department fetch api error");
                // console.log(error.title);

                // setErr(error);
                // setIsUploadedFilesQueueProcessing(false)
                // return (final_user)
            })
    }

    useEffect(()=> {
        // console.log('categorySlug:',categorySlug);
        if(slug&&!productIsLoading.current)loadProduct()
        // if(categorySlug&&!filterSubOptionsAreLoading)loadFilterSubOptions()

        updateCount(qualitySelected)
    },[slug])


    useEffect(()=> {

        updateCount(qualitySelected)
        // console.log('useeff qualityselected:',qualitySelected)

    },[qualitySelected])

    useEffect(()=> {

        updateSize(sizeSelected)
        // console.log('useeff sizeSelected:',sizeSelected)

    },[sizeSelected])

/*
    useEffect(()=> {
    console.log('useeff productchanged:')
        console.log(product)

    },[product])
*/

  const notifyAddTocart = (productItem: any) => {
      // console.log( 'adding to cart' );
       // console.log( productItem);
      cartStore.addItem(productItem)

    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={LIST_IMAGES_DEMO[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
          product={productItem}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderVariants = () => {
    if (!variants || !variants.length) {
      return null;
    }

    return (
      <div>
        <label htmlFor="">
          <span className="text-sm font-medium">
            Color:
            <span className="ml-1 font-semibold">
              {variants[variantActive].name}
            </span>
          </span>
        </label>
        <div className="flex mt-3">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative flex-1 max-w-[75px] h-10 sm:h-11 rounded-full border-2 cursor-pointer ${
                variantActive === index
                  ? "border-primary-6000 dark:border-primary-500"
                  : "border-transparent"
              }`}
            >
              <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                <img
                  src={variant.thumbnail}
                  alt=""
                  className="absolute w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
    const handleCutSelectChange = (event: { target: { value: any; }; }) => {
        const selectedValue = event.target.value;


        setProduct(prevProduct => ({
            // @ts-ignore
            ...prevProduct,
            selectedCut: selectedValue
        }));

/*
        if (selectedValue === 'damsky_strih') {
            setSizes(eshop.femaleSizes);
        } else {
            setSizes(eshop.maleSizes);
        }*/
    };
  const renderSizeList = () => {
      // console.log(sizes)
    // if (!allOfSizes || !sizes || !sizes.length) {
    if (!sizes || !sizes.length) {
      return null;
    }

      return (
        <div>

            {
                // @ts-ignore
                /* product?.type==='tShirt'&& */
                <>
                    {// @ts-ignore
                        ((product?.type==='tShirt'||product?.type==='sweatShirt')&&product?.selectedCut)&&
            <div style={{marginBottom:"10px"}}>
                <Label>Strih</Label>
                <Select className="mt-1.5" name="strih"  onChange={handleCutSelectChange} value={
                    // @ts-ignore
                    product.selectedCut}>
                    { // @ts-ignore
                        product?.cutFemale&&<option value="damsky_strih">Dámsky</option>}
                    { // @ts-ignore
                        product?.cutMale&&<option value="pansky_strih">Pánsky</option>}
                    { // @ts-ignore
                        product?.cutUnisex&&<option value="unisex_strih">Unisex</option>} { // @ts-ignore
                        product?.forKids&&<option value="detsky_strih">Detský</option>}
                </Select>
            </div>}

            <div className="flex justify-between font-medium text-sm">
                <label htmlFor="">
            <span className="">
              Vybratá veľkosť:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
                </label>
                {// @ts-ignore
                    ((product?.type==='tShirt'||product?.type==='sweatShirt'))&&
                <ButtonSecondary
                    onClick={() => setIsOpenSizeChart(true)}
                >
                    Pozri veľkostnú tabuľku
                </ButtonSecondary>
                }
            </div>
            <div className="grid grid-cols-5 sm:grid-cols-5 gap-2 mt-3">
                {sizes.map((size, index) => {
                    const isActive = size === sizeSelected;
//            const sizeOutStock = !sizes.includes(size);
                    const sizeOutStock = false
                    return (
                        <div
                            key={index}
                            className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                                sizeOutStock
                                    ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                                    : "cursor-pointer"
                            } ${
                                isActive
                                    ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                            }`}
                            onClick={() => {
                                if (sizeOutStock) {
                                    return;
                                }
                                setSizeSelected(size);
                                updateSize(size)
                            }}
                        >
                            {size}
                        </div>
                    );
                })}
            </div>
                </>}
        </div>
    );
  };

    const renderStatus = () => {
        if (!status) {
            return null;
        }
        const CLASSES =
            "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
        if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const renderSectionContent = () => {



      return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
              { // @ts-ignore
                  product?.name
              }
          </h2>

<span>{
    // @ts-ignore
    product?.product_code}</span>
          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={ // @ts-ignore
                  product?.price}
            />

            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>
              { /*
              <div className="flex items-center">

              <a
                href="#reviews"
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </a>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{status}</span>
              </div>
            </div>
            */}
          </div>

        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
          { /* <div className="">{renderVariants()}</div> */}
        <div className="">{renderSizeList()}</div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
            />
          </div>

            <ButtonPrimary
                className="flex-1 flex-shrink-0"
            onClick={() => notifyAddTocart(product)}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Pridaj do košíka</span>
          </ButtonPrimary>
        </div>

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo data={[{
            name: "Popis",
            // @ts-ignore
            content:product?.text

        }]} />

        {/* ---------- 6 ----------  */}
          {/*
          <div className="hidden xl:block">
          <Policy />
        </div>
        */}
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-2xl font-semibold">Product Details</h2>
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
          <p>
            The patented eighteen-inch hardwood Arrowhead deck --- finely
            mortised in, makes this the strongest and most rigid canoe ever
            built. You cannot buy a canoe that will afford greater satisfaction.
          </p>
          <p>
            The St. Louis Meramec Canoe Company was founded by Alfred Wickett in
            1922. Wickett had previously worked for the Old Town Canoe Co from
            1900 to 1914. Manufacturing of the classic wooden canoes in Valley
            Park, Missouri ceased in 1978.
          </p>
          <ul>
            <li>Regular fit, mid-weight t-shirt</li>
            <li>Natural color, 100% premium combed organic cotton</li>
            <li>
              Quality cotton grown without the use of herbicides or pesticides -
              GOTS certified
            </li>
            <li>Soft touch water based printed in the USA</li>
          </ul>
        </div>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div className="">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          <span className="ml-1.5"> 4,87 · 142 Reviews</span>
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            <ReviewItem />
            <ReviewItem
              data={{
                comment: `I love the charcoal heavyweight hoodie. Still looks new after plenty of washes. 
                  If you’re unsure which hoodie to pick.`,
                date: "December 22, 2021",
                name: "Stiven Hokinhs",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `The quality and sizing mentioned were accurate and really happy with the purchase. Such a cozy and comfortable hoodie. 
                Now that it’s colder, my husband wears his all the time. I wear hoodies all the time. `,
                date: "August 15, 2022",
                name: "Gropishta keo",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `Before buying this, I didn't really know how I would tell a "high quality" sweatshirt, but after opening, I was very impressed. 
                The material is super soft and comfortable and the sweatshirt also has a good weight to it.`,
                date: "December 12, 2022",
                name: "Dahon Stiven",
                starPoint: 5,
              }}
            />
          </div>

          <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all 142 reviews
          </ButtonSecondary>
        </div>
      </div>
    );
  };

    // @ts-ignore
    const [currentImage, setCurrentImage] = useState(product ? product.image : '');
    const handleThumbnailClick = (thumbPath: string) => {
        setCurrentImage(thumbPath);
    };

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}

      <main className="container mt-5 lg:mt-11 bg-white p-5">

          {product?<>

        <div className="lg:flex">
          {/* CONTENT */}
            <div className="w-full lg:w-[55%] ">
                {/* HEADING */}


                <div className="relative">


                    {/*
                <img
                  src={"https://mojavec.com/web_files/"+ // @ts-ignore
                  product?.image}
                  className="w-full rounded-2xl object-cover"
                  alt="product detail 1"
                /> */}

                    {renderStatus()}
                    {/* META FAVORITES */}
                    {/* <LikeButton className="absolute right-3 top-3 " /> */}
                </div>

                {/*
              <img src={ // @ts-ignore
                  product?.image}
                   width="250px"

              /> */}
                {<InnerImageZoom


                    src={currentImage}
                    zoomSrc={currentImage}

                    zoomType="hover"
                    zoomPreload={true}


                />

                }

                <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
                    {
                        // @ts-ignore
                        product.images?.map((item, index) => {
                            return (
                                <div
                                    key={item.id}
                                    className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                                >
                                    <img
                                        src={item.thumb_file_path}
                                        className="w-full rounded-2xl object-cover"
                                        alt="product detail 1"
                                        onClick={() => handleThumbnailClick(item.file_path)}
                                        style={{cursor:"pointer"}}
                                    />
                                </div>
                            );
                        })}
                </div>

            </div>
            {/* <InnerImageZoom src={ // @ts-ignore
                product?.image} zoomSrc={ // @ts-ignore
                product?.image}
                            zoomType="hover"
                            zoomPreload={true}


            />
            */}

            {/* SIDEBAR */}
            <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                {renderSectionContent()}
            </div>


        </div>
          </> : <div className="text-center">Je nám ľúto, produkt sme nenašli :/</div>}
          {/* DETAIL AND REVIEW */}
          <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16 bg-white p-5">
              {/*
            <div className="block xl:hidden">
            <Policy />
          </div>
          */}

          {/* renderDetailSection() */}



          {/* renderReviews() */}



          {/* OTHER SECTION
          <SectionSliderProductCard
            heading="Príbuzné produkty"
            subHeading=""
            headingFontClassName="text-2xl font-semibold"
            headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
          />
*/}
          {/* SECTION */}
          <div className="pb-20 xl:pb-28 lg:pt-14">
              {/*  <SectionPromo2 /> */ }
              {eshop.bottomProductBanner&&
                  <NcImage
                      containerClassName="flex h-full w-full flex-shrink-0 rounded-3xl overflow-hidden"
                      src={eshop.bottomProductBanner?.img}
                      onClick={() => navigate(eshop.bottomProductBanner?.link)}
                      style={{cursor: "pointer", marginTop: "60px"}}
                  />
              }
          </div>
        </div>
      </main>

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
        {/* MODAL velkostna tabulka */}
        <Dialog
            initialFocus={completeButtonRef}
            as="div"
            className="ProductDetailModalPhotos fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center"
            onClose={onCloseSizeChart}
            open={isOpenSizeChart}
        >
            <div className="relative w-full max-w-3xl bg-white dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-lg shadow-xl p-6">
                <Dialog.Overlay className="fixed inset-0" />

                {/* Close button */}
                <div
                    ref={completeButtonRef}
                    className="absolute right-4 top-4 z-50"
                >
                    <ButtonClose className="w-8 h-8" onClick={onCloseSizeChart} />
                </div>

                {/* Modal content */}
                <div className="text-center">
                    {/* Add your content here */}
                    <h2 className="text-xl font-semibold">Veľkostná tabuľka</h2>
                    <p className="mt-4">

                        {
                            // @ts-ignore
                            product?.type==='tShirt'&&
                            <>
                            {// @ts-ignore
                                (product?.selectedCut==='pansky_strih'||product?.selectedCut==='unisex_strih')&&<img src="https://mojavec.com//web_files/eV8XrB2kz0G3y1MZwyapq4onQM9mEw.jpg" />}
                                <br />
                                {// @ts-ignore
                                    product?.selectedCut==='damsky_strih'&&<img src="https://mojavec.com//web_files/27AEvXj9Q0ZoRvvAqRGlkBgVKDa864.jpg" />}
                            <br />
                                {// @ts-ignore
                                    product?.selectedCut==='detsky_strih'&&<img src="https://mojavec.com//web_files/D6592w4dpMbVyzmQYJvkG7jQOaY8Zq.jpg" />}
                            </>
                        }

 {
                            // @ts-ignore
                            product?.type==='sweatShirt'&&
     <>
     {
         /* (product?.cutMale||product?.cutUnisex)&& */
         // @ts-ignore
         (product?.selectedCut==='pansky_strih'||product?.selectedCut==='unisex_strih')&&
                       <img src="https://mojavec.com//web_files/V8bxa6q7roKNy2zZQygB1YwWPDGzdO.jpg" />}
         <br />
     {// @ts-ignore
         product?.selectedCut==='damsky_strih'&&
                        <img src="https://mojavec.com//web_files/r8Xgk3xqvep1RmoAgyOm6NGE29bA7Y.jpg" />}
     </>
                        }


                    </p>
                </div>
            </div>
        </Dialog>

    </div>
  );
};

export default ProductDetailPage;

import React, {useEffect, useRef, useState} from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {useUserStore} from "./store/userStore";
import axios from "axios";
import useEshop from "./store/eshopStore";
import {useMetaStore} from "./store/metaStore";

function App() {

 const { user, setUser, emptyUser } = useUserStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const fetchedConfig = useRef(false);
    const eshop = useEshop();
    const { title, description, image, url } = useMetaStore();

    const fetchUserStatus = async () => {
        if(!loading) {
            if (!user) {
                const jwt = localStorage.getItem('axjwt')
                if (jwt) {

                    const apiUrl = 'https://api.mojavec.com/v1/auth/'; // Replace with your API endpoint

                    try {
                        const response = await axios.get(apiUrl, {
                            headers: {
                                Authorization: `Bearer ${jwt}`,
                            },
                            params: {
                                a: 'is_logged_in',
                            },
                        });

                        setUser(response.data.user); // Assuming the API response contains a user object or null
                    } catch (err) {
                        // @ts-ignore
                        setError(err.message);
                    } finally {
                        setLoading(false);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if(!loading)fetchUserStatus();
//        console.log('app useeffect')

    }, []);
    useEffect(() => {
        // Function to fetch data from the API and update the state
        const fetchData = async () => {
            try {

                const response = await axios.get('https://api.mojavec.com/v1/get/', {
                    params: {
                        action: 'get_eshop_config',
                    },
                });

                // setEshopConfig(response.data.data); // Assuming the API returns an array of products
                /*
                        const transformedData = response.data.data.banners.map((item: any) => ({
                          image: item.file_path,
                          heading: item.name.replace(/-/g, ' '), // Replace hyphens with spaces
                          subHeading: item.link || 'Pozrite si najnovšiu ponuku!', // Fallback description
                          btnText: 'Ukáž mi',
                          btnLink: item.link || '#', // Fallback link
                          fullSize: true // Convert boolean to match the `fullSize` property
                        }));
                */

                const baseURL = "https://mojavec.com";

                const bottomHomeBannerTemp: Record<string, string> = {};
                const bottomProductBannerTemp: Record<string, string> = {};
                const transformedData = response.data.data.banners.reduce((result: Array<Record<string, any>>, item: any) => {
                    // Clean the link
                    const cleanedLink = item.link?.startsWith(baseURL) ? item.link.slice(baseURL.length) : item.link || '#';

                    if (item.bottomHome) {
                        // Populate the bottom banner object
                        bottomHomeBannerTemp.img = item.file_path || '';
                        bottomHomeBannerTemp.link = cleanedLink;
                    } else
                    if (item.bottomProduct) {
                        // Populate the bottom banner object
                        bottomProductBannerTemp.img = item.file_path || '';
                        bottomProductBannerTemp.link = cleanedLink;
                    } else {
                        // Transform and add the item to the result array
                        result.push({
                            image: item.file_path || '', // Ensure a fallback for missing paths
                            heading: item.name?.replace(/-/g, ' ') || '', // Handle missing names gracefully
                            subHeading: item.link || 'Pozrite si najnovšiu ponuku!',
                            btnText: 'Ukáž mi',
                            btnLink: cleanedLink,
                            fullSize: true,
                        });
                    }

                    return result;
                }, []);


                // console.log(transformedData)
                eshop.setSettings({ banners: transformedData });
                eshop.setSettings({ bottomHomeBanner: bottomHomeBannerTemp });
                eshop.setSettings({ bottomProductBanner: bottomProductBannerTemp });


            } catch (error) {
                //setApiError(error);
                console.error('Error fetching data:', error);
            }
        };


        if(!fetchedConfig.current){fetchData();
            fetchedConfig.current=true;
        }
    }, []);


  return (
    <HelmetProvider>
      <Helmet>
        <title>MOJA Vec | MOJA Vec</title>
        <meta
          name="E-shop MOJA Vec"
          content="MOJA Vec | MOJA Vec"
        />
      </Helmet>

      {/* MAIN APP */}
      <div className="text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200"   style={{
          backgroundImage: "url('/logo/bg1.jpg')",
          backgroundRepeat: "repeat", // Ensures it repeats on X and Y
          backgroundSize: "auto", // Keeps the original size of the image
      }}>
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;

import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useCartStore from "../../store/cartStore";
import useEshop from "../../store/eshopStore";
import React from "react";
function formatPrice(price: number) {
  // Convert the price to a string with fixed two decimal places
  let priceStr = price.toFixed(2);

  // Split the string into the whole number and decimal parts
  let parts = priceStr.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Add spaces for thousands
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Combine the parts with a comma as the decimal delimiter
  return formattedIntegerPart + ',' + decimalPart;
}
const CartPage = () => {
  const cart = useCartStore((state) => state.cart);
  const chosenDelivery = useCartStore((state) => state.chosenDelivery);
  const chosenPayment = useCartStore((state) => state.chosenPayment);
  const eshop = useEshop((state) => state.settings);
  const cartTotalPrice = useCartStore((state) => state.totalPrice);
  const addItem = useCartStore((state) => state.addItem);
  const removeItem = useCartStore((state) => state.removeItem);
  const clearCart = useCartStore((state) => state.clearCart);
  const setItemCount = useCartStore((state) => state.setItemCount);
  const renderStatusSoldout = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <NoSymbolIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Sold Out</span>
      </div>
    );
  };

  const handleCountValueChange = (id: number, size: string | undefined,color:string| undefined,cut:string| undefined, value: number) => {
    setItemCount(id,size,color,cut,value)
  };

  function getCutType(selectedCut: any) {
    switch(selectedCut) {
      case "damsky_strih":
        return "dámsky";
      case "pansky_strih":
        return "pánsky";
      case "unisex_strih":
        return "unisex";
      default:
        return "";
    }
  }

  const renderProduct = (item: Product, index: number) => {
    const { name, price, image, id,count,size, color, cut,slug, selectedCut } = item;

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
              src={"https://mojavec.com/web_files/t_"+image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link to="/product-detail" className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 ">
          <div>

              <div>
                <h3 className="text-base font-semibold">
                  <Link to="/product-detail">{name}</Link>
                </h3>
                <Prices price={price} className="mt-0.5"/>

                <div className="mt-1.5 sm:mt-2.5 mb-3 text-sm text-slate-600 dark:text-slate-300">


                  {size ? <div>
                    Veľkosť:&nbsp;
                    <span>{size}</span>
                  </div> : ''}

                  {selectedCut ? <div>
                    Strih:&nbsp;
                    <span>{getCutType(selectedCut)}</span>
                  </div> : ''}


                </div>










              <div className="text-center ">
                <NcInputNumber className="relative z-10" defaultValue={item.count}
                               min={1}
                               max={99}
                               onChange={(value) => handleCountValueChange(item.id, item.size, item.color, item.selectedCut, value)}/>
              </div>

            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">


            <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
                onClick={() => removeItem(id, size, color, selectedCut)}
            >
              <span>Odstrániť</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CartPage">
      <Helmet>
        <title>Nákupný košík | MojaVec</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Nákupný košík
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Úvodná strana
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Nákupný košík</span>
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
            {cart.map(renderProduct)}
          </div>
          <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
          <div className="flex-1">
            <div className="sticky top-28">


                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Celková suma tovaru</span>
                  <span>{formatPrice(cartTotalPrice)} €</span>
                </div>

              <ButtonPrimary href="/checkout" className="mt-8 w-full">
                Prejsť do objednania
              </ButtonPrimary>

            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CartPage;
